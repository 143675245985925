import { getCityTile } from '@/pages/TabPages/OrganizerPage/utils';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import { Cell, IconContainer } from '@telegram-apps/telegram-ui';
import { FC } from 'react';
import { BiSolidGame } from 'react-icons/bi';

type Props = {
  item: AdminOrganizerOnCity;
  onClick: () => void;
};

export const OrganizerOnCityItem: FC<Props> = ({ item, onClick }) => {
  const { OrganizerOnCity } = item;
  const { Organizer, City } = OrganizerOnCity;
  const subTitle = getCityTile(City);
  return (
    <Cell
      onClick={onClick}
      subtitle={subTitle}
      before={
        <IconContainer>
          <BiSolidGame size={24} />
        </IconContainer>
      }
    >
      {Organizer.title}
    </Cell>
  );
};
