import { getSchedule } from '@/api/schedule';
import { PageTitle } from '@/components/PageTitle';
import { DEFAULT_CITY } from '@/shared/services/http/consts';
import { ScheduleGame } from '@/shared/services/http/schedule';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { Button, List, Spinner, Text } from '@telegram-apps/telegram-ui';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { ScheduleItem } from './components/ScheduleItem';

import styles from './index.module.css';

const TITLE = 'Расписание';
const SUB_TITLE = 'г. Киров';

export const Content = () => {
  const { initDataRaw = '' } = retrieveLaunchParams();

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<ScheduleGame[]>();

  const getData = async () => {
    setIsError(false);
    setIsLoading(true);
    try {
      // TODO: При реализации выбора городов, устанавливать тут город
      const r = await getSchedule(initDataRaw, DEFAULT_CITY);
      setList(sortBy(r, (v) => v.scheduledAt));
    } catch (error) {
      setIsError(true);
      // TODO
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRefresh = () => {
    getData();
  };

  if (isLoading) {
    return (
      <List className={styles.padding}>
        <PageTitle title={TITLE} subTitle={SUB_TITLE} />
        <Spinner size="s" className={styles.loading} />
      </List>
    );
  }

  if (isError) {
    return (
      <List className={styles.padding}>
        <PageTitle title={TITLE} subTitle={SUB_TITLE} />
        <Text>Не удалось загрузить расписание игр, попробуйте зайти позже</Text>
        <Button onClick={handleRefresh}>Попробовать еще раз</Button>
      </List>
    );
  }

  if (list === undefined) {
    return null;
  }

  return (
    <List className={styles.padding}>
      <PageTitle title={TITLE} subTitle={SUB_TITLE} />
      {list.map((item) => (
        <ScheduleItem key={item.id} item={item} />
      ))}
    </List>
  );
};
