export const convertToNumber = (value: string | number | null | undefined) => {
  if (value === null) {
    return null;
  }
  if (value === undefined) {
    return null;
  }
  if (typeof value === 'string' && !value.trim()) {
    return null;
  }
  const n = +value;
  if (isNaN(n)) {
    return null;
  }
  return n;
};
