import { OrganizerOnCityAdmin } from '@/shared/services/http/organizer';
import { Cell, IconContainer } from '@telegram-apps/telegram-ui';
import { FC } from 'react';
import { BiUser } from 'react-icons/bi';

type Props = {
  item: OrganizerOnCityAdmin;
  onClick?: () => void;
};

export const AdminItem: FC<Props> = ({ item, onClick }) => {
  const { User } = item;
  const { telegramUsername, telegramFirstName, telegramLastName } = User;
  const name = [telegramFirstName, telegramLastName]
    .filter((v) => v?.trim())
    .join(' ');
  return (
    <Cell
      onClick={onClick}
      subtitle={telegramUsername?.trim()}
      before={
        <IconContainer>
          <BiUser size={24} />
        </IconContainer>
      }
    >
      {name}
    </Cell>
  );
};
