import { gameCreateOrganizer } from '@/api/organizer';
import { TextInput } from '@/components/Inputs/TextInput';
import { PageTitle } from '@/components/PageTitle';
import { Game } from '@/shared/prisma';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import {
  initMainButton,
  initPopup,
  retrieveLaunchParams,
} from '@telegram-apps/sdk-react';
import {
  Cell,
  IconContainer,
  Section,
  Switch,
  Textarea,
} from '@telegram-apps/telegram-ui';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { DraftStatusIcon } from '../OrganizerOnCityGamesPage/components/GameItem/DraftStatusIcon';
import { CostInput } from './components/CostInput';
import { GameDateTimeSelect } from './components/GameDateTimeSelect';
import { useGameForm } from './hooks/useGameForm';
import { dateParse } from './utils';

import styles from './index.module.css';

export const OrganizerOnCityGamePage = () => {
  const location = useLocation();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const item = location.state.item as AdminOrganizerOnCity;
  const game = location.state.game as Game | undefined;
  const isCanEdit = location.state.isCanEdit as boolean | undefined;

  const {
    isNewGame,
    formData,
    updateFormField,
    updateFormNumericField,
    getFormFieldValue,
    getFormSaveData,
  } = useGameForm({
    initData: game,
  });

  const [isPublish, setIsPublish] = useState(false);

  useEffect(() => {
    if (location.pathname !== '/organizer/game/') {
      return;
    }
    if (!isCanEdit) {
      return;
    }
    const [mainButton] = initMainButton();
    mainButton.enable().show();
    return () => {
      mainButton.disable().hide();
    };
  }, [location.pathname, isCanEdit]);

  useEffect(() => {
    const title = isNewGame
      ? isPublish
        ? 'Сохранить и опубликовать'
        : 'Сохранить как черновик'
      : 'Сохранить';
    const [mainButton] = initMainButton();
    mainButton.enable().show().setText(title);
    forceUpdate();
  }, [isNewGame, isPublish]);

  const handleSaveClick = useCallback(async () => {
    const formSaveData = getFormSaveData(formData);
    if (typeof formSaveData === 'string') {
      const popup = initPopup();
      popup.open({
        message: formSaveData,
        buttons: [{ type: 'default', text: 'OK' }],
      });
      return;
    }
    const { initDataRaw = '' } = retrieveLaunchParams();
    if (isNewGame) {
      try {
        const r = await gameCreateOrganizer(initDataRaw, {
          gameSourceId: item.OrganizerOnCity.gameSourceId,
          Game: formSaveData,
        });
        console.log({ r });
      } catch {
        console.log('Error saving', formSaveData);
      }
    }
  }, [formData]);

  useEffect(() => {
    const [mainButton] = initMainButton();
    mainButton.on('click', handleSaveClick);
    return () => {
      mainButton.off('click', handleSaveClick);
    };
  }, [handleSaveClick]);

  const { OrganizerOnCity } = item;
  const { Organizer, City } = OrganizerOnCity;

  const handleChangeIsPublish = (
    e: React.MouseEvent | React.ChangeEvent,
    value: boolean
  ) => {
    e.preventDefault();
    setIsPublish(value);
  };

  const subTitle = getCityTile(City);
  const isShowSwitch = !game || game.isDraft;

  return (
    <div className={styles.padding}>
      <PageTitle title={Organizer.title} subTitle={subTitle} />
      <div className={styles.content}>
        {game && (
          <Section header="Статус">
            <Cell
              readOnly
              before={
                <IconContainer>
                  <DraftStatusIcon isDraft={game.isDraft} />
                </IconContainer>
              }
            >
              {game.isDraft ? 'Черновик' : 'Опубликована'}
            </Cell>
          </Section>
        )}
        <Section header="Об игре" />
        <TextInput
          placeholder="Название"
          value={formData.title}
          onChange={(e) => updateFormField('title', e.target.value)}
          readOnly={!isCanEdit}
        />
        <br />
        <Textarea
          placeholder="Описание"
          value={getFormFieldValue('description')}
          onChange={(e) => updateFormField('description', e.target.value)}
          readOnly={!isCanEdit}
        />
        <br />
        <CostInput
          placeholder="Цена"
          value={getFormFieldValue('cost')}
          onChange={(value) => updateFormNumericField('cost', value)}
          readOnly={!isCanEdit}
        />
        <Section header="Дата, время и место" />
        <GameDateTimeSelect
          value={dateParse(formData.scheduledAt)}
          onChange={(value) => updateFormField('scheduledAt', value)}
          readOnly={!isCanEdit}
        />
        <br />
        <Section header="Ссылки" />
        <TextInput
          placeholder="Ссылка на регистрацию"
          value={getFormFieldValue('registerLink')}
          onChange={(e) => updateFormField('registerLink', e.target.value)}
          readOnly={!isCanEdit}
        />
        <br />
        <TextInput
          placeholder="Ссылка на картинку"
          value={getFormFieldValue('imageUrl')}
          onChange={(e) => updateFormField('imageUrl', e.target.value)}
          readOnly={!isCanEdit}
        />
        {isShowSwitch && (
          <Section
            header={`Расписание ${Organizer.title}`}
            footer={
              isPublish
                ? 'После сохранения игра сразу же будет опубликована в расписании.'
                : 'После сохранения игра не будет опубликована в расписании.'
            }
          >
            <Cell
              onClick={(e) => handleChangeIsPublish(e, !isPublish)}
              after={
                <Switch
                  checked={isPublish}
                  onChange={(e) => handleChangeIsPublish(e, !isPublish)}
                />
              }
            >
              Опубликовать
            </Cell>
          </Section>
        )}
        {/* {game && (
          <Section header="Статус">
            <Cell
              readOnly
              before={
                <IconContainer>
                  <BiTime size={24} />
                </IconContainer>
              }
              subhead="Создана"
              multiline
            >
              {getHumanDateTime(game.createdAt)}
            </Cell>
            <Cell
              readOnly
              before={
                <IconContainer>
                  <BiTime size={24} />
                </IconContainer>
              }
              subhead="Обновлена"
              multiline
            >
              {getHumanDateTime(game.updatedAt)}
            </Cell>
          </Section>
        )} */}
      </div>
    </div>
  );
};
