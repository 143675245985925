import { City } from '@/shared/prisma';
import { DEFAULT_CITY } from '@/shared/services/http/consts';

export const getCityTile = (city: City) => {
  const { countryName } = DEFAULT_CITY;
  if (city.countryName === countryName) {
    return city.name;
  }
  return `${city.name} · ${city.countryName}`;
};
