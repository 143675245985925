import { GameCreate } from '@/shared/services/http/organizer';
import { useState } from 'react';

type GameFormData = Partial<GameCreate>;
type GameFormSaveData = GameCreate;

type Props = {
  initData?: GameFormData;
};

export const useGameForm = ({ initData }: Props) => {
  const isNewGame = !initData;
  const [formData, setFormData] = useState<GameFormData>(initData ?? {});

  const updateFormField = (key: keyof GameFormData, value: unknown) => {
    if (typeof value === 'string' && !value.trim()) {
      setFormData({ ...formData, [key]: null });
      return;
    }
    setFormData({ ...formData, [key]: value });
  };

  const updateFormNumericField = (
    key: keyof GameFormData,
    value: number | null
  ) => {
    setFormData({ ...formData, [key]: value });
  };

  const getFormFieldValue = (key: keyof GameFormData) => {
    const value = formData[key];
    if (!value) {
      return '';
    }
    return value.toString();
  };

  const getFormSaveData = (
    validateFormData: GameFormData
  ): string | GameFormSaveData => {
    if (!validateFormData.title) {
      return 'Введите Название игры.';
    }
    if (!validateFormData.scheduledAt) {
      return 'Введите Дату и Время игры.';
    }
    return {
      ...validateFormData,
      title: validateFormData.title,
      scheduledAt: validateFormData.scheduledAt,
    };
  };

  return {
    isNewGame,
    formData,
    updateFormField,
    updateFormNumericField,
    getFormFieldValue,
    getFormSaveData,
  };
};
