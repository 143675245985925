import logo from '@/assets/logo.png';

import styles from './index.module.css';

export const LogoHeader = () => {
  return (
    <div className={styles.header}>
      <img
        alt="Лого"
        src={logo}
        style={{
          display: 'block',
          objectFit: 'cover',
          height: '16vh',
          width: '16vh',
        }}
      />
    </div>
  );
};
