import { GameSourceType } from '@/shared/prisma';

export const getGameSourceTitle = (gameSourceType: GameSourceType) => {
  switch (gameSourceType) {
    case 'Manual':
      return 'Ручной';
    case 'WowQuizFetcher':
      return 'WOW';
    case 'LigaIndigoFetcher':
      return 'LigaIndigo';
    case 'GoogleSpreadSheetFetcher':
      return 'GoogleSpreadSheet';
    case 'QuizPleaseFetcher':
      return 'QuizPlease';
    case 'RudaGamesFetcher':
      return 'RudaGames';
    case 'ShakerQuizFetcher':
      return 'ShakerQuiz';
    default:
      return gameSourceType;
  }
};

export const isManualMode = (gameSourceType: GameSourceType) => {
  switch (gameSourceType) {
    case 'Manual':
      return true;
  }
  return false;
};
