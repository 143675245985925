import { AuthType } from '@/shared/services/http/auth';
import {
  ConnectOrganizerBody,
  ConnectOrganizerResponse,
  CreateOrganizerBody,
  CreateOrganizerResponse,
  GameCreateOrganizerBody,
  GameCreateOrganizerResponse,
  GameDeleteOrganizerBody,
  GameDeleteOrganizerResponse,
  GameListOrganizerBody,
  GameListOrganizerResponse,
  GameUpdateOrganizerBody,
  GameUpdateOrganizerResponse,
  ListOrganizerResponse,
  StatOrganizerBody,
  StatOrganizerResponse,
  UpdateOrganizerBody,
  UpdateOrganizerResponse,
} from '@/shared/services/http/organizer';
import request from 'umi-request';
import { API } from '../../const/env';

export const getListOrganizer = async (initDataRaw: string) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<ListOrganizerResponse>(`${API}/organizer/list`, {
    method: 'GET',
    headers: {
      authorization,
    },
  });
};

export const createOrganizer = async (
  initDataRaw: string,
  data: CreateOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<CreateOrganizerResponse>(`${API}/organizer/create`, {
    method: 'POST',
    headers: {
      authorization,
    },
    data,
  });
};

export const updateOrganizer = async (
  initDataRaw: string,
  data: UpdateOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<UpdateOrganizerResponse>(`${API}/organizer/update`, {
    method: 'POST',
    headers: {
      authorization,
    },
    data,
  });
};

export const organizerConnect = async (
  initDataRaw: string,
  data: ConnectOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<ConnectOrganizerResponse>(`${API}/organizer/connect`, {
    method: 'POST',
    headers: {
      authorization,
    },
    data,
  });
};

export const organizerStat = async (
  initDataRaw: string,
  data: StatOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<StatOrganizerResponse>(`${API}/organizer/stat`, {
    method: 'POST',
    headers: {
      authorization,
    },
    data,
  });
};

export const gamesListOrganizer = async (
  initDataRaw: string,
  data: GameListOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GameListOrganizerResponse>(
    `${API}/organizer/game/list`,
    {
      method: 'POST',
      headers: {
        authorization,
      },
      data,
    }
  );
};

export const gameCreateOrganizer = async (
  initDataRaw: string,
  data: GameCreateOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GameCreateOrganizerResponse>(
    `${API}/organizer/game/create`,
    {
      method: 'POST',
      headers: {
        authorization,
      },
      data,
    }
  );
};

export const gameUpdateOrganizer = async (
  initDataRaw: string,
  data: GameUpdateOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GameUpdateOrganizerResponse>(
    `${API}/organizer/game/update`,
    {
      method: 'POST',
      headers: {
        authorization,
      },
      data,
    }
  );
};

export const gameDeleteOrganizer = async (
  initDataRaw: string,
  data: GameDeleteOrganizerBody
) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GameDeleteOrganizerResponse>(
    `${API}/organizer/game/delete`,
    {
      method: 'POST',
      headers: {
        authorization,
      },
      data,
    }
  );
};
