import { useIntegration } from '@telegram-apps/react-router-integration';
import { initNavigator, SDKProvider } from '@telegram-apps/sdk-react';
import { AppRoot } from '@telegram-apps/telegram-ui';
import { useEffect, useMemo } from 'react';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import { AppAuth } from './components/AppAuth';
import { AppInit } from './components/AppInit';
import { ScheduleGameCalendarDownloadPage } from './pages/ScheduleGameCalendarDownloadPage';
import { ScheduleGamePage } from './pages/ScheduleGamePage';
import { routes } from './routes';
import useCitiesStore from './stores/useCitiesStore';
import useOrganizerStore from './stores/useOrganizerStore';

import 'moment/locale/ru';

export const App = () => {
  const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
  const [location, reactNavigator] = useIntegration(navigator);

  const { refreshCities } = useCitiesStore();
  const { refreshListOrganizer } = useOrganizerStore();

  useEffect(() => {
    navigator.attach();
    return () => navigator.detach();
  }, [navigator]);

  useEffect(() => {
    refreshCities();
    refreshListOrganizer();
  }, []);

  if (window.location.pathname.match(/\/game-calendar-ics\//gm)) {
    return (
      <AppRoot>
        <ScheduleGameCalendarDownloadPage />
      </AppRoot>
    );
  }

  if (window.location.pathname.match(/\/game\//gm)) {
    return (
      <AppRoot>
        <ScheduleGamePage />
      </AppRoot>
    );
  }

  return (
    <SDKProvider>
      <AppRoot>
        <AppAuth>
          <AppInit />
          <Router location={location} navigator={reactNavigator}>
            <Routes>
              {routes.map((route) => (
                <Route key={route.path} {...route} />
              ))}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Router>
        </AppAuth>
      </AppRoot>
    </SDKProvider>
  );
};
