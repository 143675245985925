import { LogoHeader } from '@/components/LogoHeader';
import { Space } from '@/components/Space';
import { Button, Text, Title } from '@telegram-apps/telegram-ui';
import { BiLogoTelegram } from 'react-icons/bi';

import styles from './index.module.css';

export const Content = () => {
  const handleConnectToAuthor = () => {
    window.open('https://t.me/rigerous');
  };
  return (
    <div className={styles.centerPadding}>
      <Space direction="vertical" className={styles.center}>
        <LogoHeader />
        <Space direction="vertical" className={styles.center} size="large">
          <Title level="2" weight="1">
            КОГДА КВИЗ?
          </Title>
          <Text className={styles.description}>
            Сервис для просмотра расписания квиз-игр в твоем городе.
          </Text>
          <Space direction="vertical" className={styles.center}>
            <Text>Связаться с автором</Text>
            <Button
              mode="bezeled"
              before={<BiLogoTelegram size={20} />}
              onClick={handleConnectToAuthor}
            >
              @rigerous
            </Button>
          </Space>
        </Space>
      </Space>
    </div>
  );
};
