import { postEvent } from '@telegram-apps/sdk-react';
import { useEffect } from 'react';

export const AppInit = () => {
  useEffect(() => {
    postEvent('web_app_expand');
    // web_app_expand
  }, []);
  return null;
};
