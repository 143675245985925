import moment from 'moment';

type Options = {
  isWithTime?: boolean;
};

export const getHumanDateTime = (date: Date, options: Options = {}) => {
  const { isWithTime = true } = options;
  let format = 'D MMMM (dddd), в HH:mm';
  if (!isWithTime) {
    format = 'D MMMM (dddd)';
  }
  const dateStr = moment(date).tz('Europe/Moscow').locale('ru').format(format);
  if (moment().tz('Europe/Moscow').isSame(date, 'day')) {
    return `Сегодня, ${dateStr}`;
  }
  if (moment().tz('Europe/Moscow').add(1, 'day').isSame(date, 'day')) {
    return `Завтра, ${dateStr}`;
  }
  return dateStr;
};
