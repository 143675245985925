import { gamesListOrganizer } from '@/api/organizer';
import { PageTitle } from '@/components/PageTitle';
import { Game } from '@/shared/prisma';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import { initMainButton, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { Section, Spinner, Text } from '@telegram-apps/telegram-ui';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { GameItem } from './components/GameItem';

import styles from './index.module.css';

export const OrganizerOnCityGamesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const item = location.state.item as AdminOrganizerOnCity;
  const isCanEdit = location.state.isCanEdit as boolean;

  const [games, setGames] = useState<Game[]>();

  const { OrganizerOnCity } = item;
  const { Organizer, City, GameSource } = OrganizerOnCity;

  const refreshGames = async (gameSourceId: string) => {
    try {
      const { initDataRaw = '' } = retrieveLaunchParams();
      setGames(await gamesListOrganizer(initDataRaw, { gameSourceId }));
    } catch (error) {
      console.log('Cannot load games', error);
    }
  };

  useEffect(() => {
    refreshGames(GameSource.id);
  }, [GameSource.id]);

  useEffect(() => {
    if (location.pathname !== '/organizer/games/') {
      return;
    }
    if (!isCanEdit) {
      return;
    }
    const [mainButton] = initMainButton();
    mainButton
      .setText('Добавить игру')
      .enable()
      .show()
      .on('click', handleAddGameClick);
    return () => {
      mainButton.disable().hide().off('click', handleAddGameClick);
    };
  }, [location.pathname, isCanEdit]);

  const handleAddGameClick = () => {
    navigate('/organizer/game/', { state: { item, isCanEdit } });
  };

  const handleGameClick = (game: Game) => {
    navigate('/organizer/game/', {
      state: { item, game, isCanEdit },
    });
  };

  const subTitle = getCityTile(City);

  return (
    <div className={styles.padding}>
      <PageTitle title={Organizer.title} subTitle={subTitle} />
      <div className={styles.content}>
        {games === undefined && (
          <div className={styles.emptyWrapper}>
            <Spinner className={styles.empty} size="s" />
          </div>
        )}
        {games?.length === 0 && (
          <div className={styles.emptyWrapper}>
            <Text className={styles.empty}>Игр в расписание не добавлено.</Text>
          </div>
        )}
        {games && games.length > 0 && (
          <Section header="Список игр">
            {games.map((game) => (
              <GameItem
                key={game.id}
                item={game}
                onClick={() => handleGameClick(game)}
              />
            ))}
          </Section>
        )}
      </div>
    </div>
  );
};
