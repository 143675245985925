import { Button, Card, Text } from '@telegram-apps/telegram-ui';
import { Space } from '@/components/Space';
import { API } from '@/const/env';
import { useEffect } from 'react';

import styles from './index.module.css';

type Props = {
  slug: string;
};

export const DownloadTip: React.FC<Props> = ({ slug }) => {
  const fileName = `${slug}.ics`;
  const downloadLink = `${API}/calendar/ics/${fileName}`;

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = downloadLink;
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleDownload();
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Card className={styles.card}>
      <div className={styles.content}>
        <Space direction="vertical">
          <Text>
            Через некоторое время начнется скачивание файла, если
            этого не произошло, нажмите кнопку Загрузить файл для календаря.
          </Text>
          <Text>
            После загрузки файла {fileName}, откройте его приложением Календарь.
          </Text>
          <Button
            mode="bezeled"
            style={{ marginTop: 12 }}
            onClick={handleDownload}
          >
            Загрузить файл для календаря
          </Button>
        </Space>
      </div>
    </Card>
  );
};
