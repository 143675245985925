import { AppRootContext } from '@telegram-apps/telegram-ui/dist/components/Service/AppRoot/AppRootContext';
import { useContext } from 'react';

export const useAppRootContext = () => {
  const appRootContext = useContext(AppRootContext);

  if (!appRootContext.isRendered) {
    throw new Error('[TGUI] Wrap your app with <AppRoot> component');
  }

  return appRootContext;
};
