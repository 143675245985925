import { PageTitle } from '@/components/PageTitle';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import { initMainButton } from '@telegram-apps/sdk-react';
import { Section } from '@telegram-apps/telegram-ui';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { AdminItem } from './components/AdminItem';
import { ShareTokenModal } from './components/ShareTokenModal';

import styles from './index.module.css';

export const OrganizerOnCityAdminPage = () => {
  const location = useLocation();
  const item = location.state.item as AdminOrganizerOnCity;

  const [isShareTokenOpen, setIsShareTokenOpen] = useState(false);

  const { OrganizerOnCity } = item;
  const { Organizer, City, Admins } = OrganizerOnCity;
  const subTitle = getCityTile(City);

  useEffect(() => {
    if (location.pathname !== '/organizer/admin/') {
      return;
    }
    if (isShareTokenOpen) {
      return;
    }
    const [mainButton] = initMainButton();
    mainButton
      .setText('Добавить администратора')
      .enable()
      .show()
      .on('click', handleAddUserClick);
    return () => {
      mainButton.disable().hide().off('click', handleAddUserClick);
    };
  }, [location.pathname, isShareTokenOpen]);

  const handleAddUserClick = () => {
    setIsShareTokenOpen(true);
  };

  const handleShareTokenModalOpenChange = (isOpen: boolean) => {
    setIsShareTokenOpen(isOpen);
  };

  return (
    <>
      <div className={styles.padding}>
        <PageTitle title={Organizer.title} subTitle={subTitle} />
        <div className={styles.content}>
          <Section header="Список администраторов">
            {Admins.map((admin) => (
              <AdminItem key={admin.id} item={admin} />
            ))}
            {/* <ButtonCell
              before={
                <IconContainer>
                  <BiShareAlt size={16} />
                </IconContainer>
              }
              onClick={handleAddUserClick}
            >
              Добавить администратора
            </ButtonCell> */}
          </Section>
        </div>
      </div>
      <ShareTokenModal
        organizerOnCityId={item.organizerOnCityId}
        subject={`${Organizer.title} (${subTitle})`}
        isOpen={isShareTokenOpen}
        onOpenChange={handleShareTokenModalOpenChange}
      />
    </>
  );
};
