import { organizerConnect } from '@/api/organizer';
import { Space } from '@/components/Space';
import {
  initHapticFeedback,
  retrieveLaunchParams,
} from '@telegram-apps/sdk-react';
import {
  Button,
  Modal,
  Placeholder,
  Spinner,
  Text,
} from '@telegram-apps/telegram-ui';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BiCheck, BiSolidCopy } from 'react-icons/bi';

import styles from './index.module.css';

type Props = {
  organizerOnCityId: string;
  subject: string;
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const ShareTokenModal: React.FC<Props> = ({
  organizerOnCityId,
  subject,
  isOpen,
  onOpenChange,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const [token, setToken] = useState<string>();

  useEffect(() => {
    const getToken = async () => {
      try {
        const { initDataRaw = '' } = retrieveLaunchParams();
        const r = await organizerConnect(initDataRaw, { organizerOnCityId });
        setToken(r.connectToken);
      } catch {
        setToken('');
      }
    };
    getToken();
  }, []);

  useEffect(() => {
    if (!isCopied) {
      return;
    }
    const timeout = setTimeout(() => {
      closeModal();
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setIsCopied(false);
    // setToken(undefined);
  }, [isOpen]);

  const closeModal = () => {
    onOpenChange(false);
  };

  const handleOnCopy = () => {
    const hapticFeedback = initHapticFeedback();
    hapticFeedback.impactOccurred('soft');
    setIsCopied(true);
  };

  const tokenLink = token
    ? `https://telegram.me/KogdaQuizBot?start=${token}`
    : undefined;

  return (
    <Modal open={isOpen} onOpenChange={onOpenChange} className={styles.modal}>
      <Placeholder header="Добавить администратора" />
      <Space direction="vertical" className={styles.padding}>
        <Text className={styles.text}>
          Передайте ссылку пользователю, которому вы доверяете.
        </Text>
        <Text className={styles.text}>
          Пользователь перейдет по ссылке и подключится к администрированию{' '}
          <b>{subject}</b>.
        </Text>
        <Text className={styles.text}>Ссылка действует 1 раз.</Text>
        {token === undefined && (
          <Text className={styles.text}>
            <Spinner size="s" />
          </Text>
        )}
        {token === '' && (
          <Text className={styles.text}>Ошибка при получении кода.</Text>
        )}
        {token && tokenLink && (
          <CopyToClipboard text={tokenLink} onCopy={handleOnCopy}>
            <Button
              mode="outline"
              size="l"
              className={styles.button}
              before={
                isCopied ? <BiCheck size={24} /> : <BiSolidCopy size={24} />
              }
              onClick={handleOnCopy}
            >
              {isCopied ? 'Скопирована' : tokenLink}
            </Button>
          </CopyToClipboard>
        )}
      </Space>
    </Modal>
  );
};
