import { getListOrganizer } from '@/api/organizer';
import { ListOrganizerResponse } from '@/shared/services/http/organizer';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { create } from 'zustand';

type OrganizerStore = {
  listOrganizer: ListOrganizerResponse;
  refreshListOrganizer: () => Promise<void>;
};

const useOrganizerStore = create<OrganizerStore>((set) => ({
  listOrganizer: [],
  refreshListOrganizer: async () => {
    const { initDataRaw = '' } = retrieveLaunchParams();
    const listOrganizer = await getListOrganizer(initDataRaw);
    set(() => ({ listOrganizer }));
  },
}));

export default useOrganizerStore;
