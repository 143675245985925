import { TextInput } from '@/components/Inputs/TextInput';
import { InputProps, Text } from '@telegram-apps/telegram-ui';
import { FC, useMemo } from 'react';
import { convertToNumber } from './utils';

type Props = Omit<InputProps, 'value' | 'onChange'> & {
  value: string | number | null | undefined;
  onChange: (value: number | null) => void;
};

export const CostInput: FC<Props> = ({ value, onChange, ...rest }) => {
  const inputValue = useMemo(() => {
    const numberValue = convertToNumber(value);
    if (numberValue === null) {
      return '';
    }
    return numberValue.toString();
  }, [value]);
  const handleChange = (newValue: string) => {
    onChange(convertToNumber(parseInt(newValue)));
  };
  return (
    <TextInput
      {...rest}
      value={inputValue}
      onChange={(e) => handleChange(e.target.value)}
      after={<Text>₽</Text>}
      pattern="\d*"
    />
  );
};
