import { PageTitle } from '@/components/PageTitle';
import { AdminOrganizerOnCity } from '@/shared/services/http/organizer';
import { getHumanDateTime } from '@/utils/getHumanDateTime';
import { Cell, IconContainer, Section, Text } from '@telegram-apps/telegram-ui';
import { BiTime, BiWorld } from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { getGameSourceTitle } from '../OrganizerOnCityIndexPage/utils';

import styles from './index.module.css';

export const OrganizerOnCitySourcePage = () => {
  const location = useLocation();
  const item = location.state.item as AdminOrganizerOnCity;

  const { OrganizerOnCity } = item;
  const { Organizer, City, GameSource } = OrganizerOnCity;
  const { createdAt, lastUpdateAt, nextUpdateAfter, settings, type } =
    GameSource;
  const subTitle = getCityTile(City);

  const gameSourceTitle = getGameSourceTitle(type);

  return (
    <div className={styles.padding}>
      <PageTitle title={Organizer.title} subTitle={subTitle} />
      <div className={styles.content}>
        <Section header="Источник данных">
          <Cell
            readOnly
            before={
              <IconContainer>
                <BiWorld size={24} />
              </IconContainer>
            }
            subhead="Загрузчик"
          >
            {gameSourceTitle}
          </Cell>
        </Section>
        <Section header="Активность">
          <Cell
            readOnly
            before={
              <IconContainer>
                <BiTime size={24} />
              </IconContainer>
            }
            subhead="Создан"
            multiline
          >
            {getHumanDateTime(createdAt)}
          </Cell>
          {lastUpdateAt && (
            <Cell
              readOnly
              before={
                <IconContainer>
                  <BiTime size={24} />
                </IconContainer>
              }
              subhead="Последнее обновление"
              multiline
            >
              {getHumanDateTime(lastUpdateAt)}
            </Cell>
          )}
          {nextUpdateAfter && (
            <Cell
              readOnly
              before={
                <IconContainer>
                  <BiTime size={24} />
                </IconContainer>
              }
              subhead="Следующее обновление"
              multiline
            >
              {getHumanDateTime(nextUpdateAfter)}
            </Cell>
          )}
        </Section>
        <Section header="Настройки">
          <div className={styles.settingsWrapper}>
            <Text className={styles.settings}>{JSON.stringify(settings)}</Text>
          </div>
        </Section>
      </div>
    </div>
  );
};
