import { ScheduleGame } from '@/shared/services/http/schedule';
import { getHumanDateTime } from '@/utils/getHumanDateTime';
import { FC } from 'react';
import { Helmet } from 'react-helmet';

type Props = {
  quiz: ScheduleGame;
};

export const PageHelmet: FC<Props> = ({ quiz }) => {
  const { scheduledAt, title, imageUrl, description } = quiz;

  const dateStr = getHumanDateTime(scheduledAt);

  return (
    <Helmet htmlAttributes={{ translate: 'no' }}>
      <html lang="ru" prefix="og: http://ogp.me/ns#" />
      <meta property="og:type" content="website" />
      <title>{title}</title>
      <meta property="og:title" content={`${title} · ${dateStr}`} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {description && <meta property="description" content={description} />}
    </Helmet>
  );
};
