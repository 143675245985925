import { create } from 'zustand';
import { GetCitiesResponse } from '@/shared/services/http/city';
import { getCities } from '@/api/city';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';

type CitiesStore = {
  cities: GetCitiesResponse | undefined;
  refreshCities: () => Promise<void>;
};

const useCitiesStore = create<CitiesStore>((set) => ({
  cities: undefined,
  refreshCities: async () => {
    const { initDataRaw = '' } = retrieveLaunchParams();
    const cities = await getCities(initDataRaw);
    set(() => ({ cities }));
  },
}));

export default useCitiesStore;
