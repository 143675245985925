import { AuthType } from '@/shared/services/http/auth';
import { GetCitiesResponse } from '@/shared/services/http/city';
import request from 'umi-request';
import { API } from '../../const/env';

export const getCities = async (initDataRaw: string) => {
  const authorization = `${AuthType.TMA} ${initDataRaw}`;
  return await request<GetCitiesResponse>(`${API}/city/list`, {
    method: 'GET',
    headers: {
      authorization,
    },
  });
};
