import moment from 'moment';

export const getDateStrFromDateTime = (dateTime: Date) => {
  return moment(dateTime).format('YYYY-MM-DD');
};

export const changeDateInDateTime = (date: string, dateTime: Date) => {
  return moment(
    `${moment(date).format('YYYY-MM-DD')} ${moment(dateTime).format(
      'HH:mm:ss'
    )}`
  ).toDate();
};
