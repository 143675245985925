import { FC } from 'react';
import { BiCheckCircle, BiEditAlt } from 'react-icons/bi';

import styles from './index.module.css';

type Props = {
  isDraft: boolean;
};

export const DraftStatusIcon: FC<Props> = ({ isDraft }) => {
  if (isDraft) {
    return <BiEditAlt size={24} className={styles.draftIcon} />;
  }
  return <BiCheckCircle size={24} className={styles.publishedIcon} />;
};
