import { organizerStat } from '@/api/organizer';
import { PageTitle } from '@/components/PageTitle';
import {
  AdminOrganizerOnCity,
  StatOrganizerResponse,
} from '@/shared/services/http/organizer';
import { retrieveLaunchParams } from '@telegram-apps/sdk-react';
import {
  Caption,
  Cell,
  IconContainer,
  Section,
} from '@telegram-apps/telegram-ui';
import plural from 'plural-ru';
import { useEffect, useState } from 'react';
import {
  BiCalendarCheck,
  BiDownload,
  BiGame,
  BiLockOpen,
} from 'react-icons/bi';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCityTile } from '../../../utils';
import { getGameSourceTitle, isManualMode as isManualModeFn } from './utils';

import styles from './index.module.css';

export const OrganizerOnCityIndexPage = () => {
  const location = useLocation();
  const item = location.state.item as AdminOrganizerOnCity;
  const navigate = useNavigate();

  const [stat, setStat] = useState<StatOrganizerResponse>();

  useEffect(() => {
    const getStat = async () => {
      try {
        const { initDataRaw = '' } = retrieveLaunchParams();
        setStat(
          await organizerStat(initDataRaw, {
            organizerOnCityId: item.organizerOnCityId,
          })
        );
      } catch {
        console.log(`Cannot get stat for ${item.organizerOnCityId}`);
      }
    };
    getStat();
  }, [item]);

  const { OrganizerOnCity } = item;
  const { Organizer, City, GameSource } = OrganizerOnCity;
  const subTitle = getCityTile(City);

  const gameSourceTitle = getGameSourceTitle(GameSource.type);
  const scheduleFooter = stat?.usersCount
    ? `Охват ${stat.usersCount} ${plural(
        stat.usersCount,
        'пользователь',
        'пользователя',
        'пользователей'
      )}.`
    : undefined;
  const isManualMode = isManualModeFn(GameSource.type);

  return (
    <div className={styles.padding}>
      <PageTitle title={Organizer.title} subTitle={subTitle} />
      <div className={styles.content}>
        <Section header="Настройки">
          <Cell
            before={
              <IconContainer>
                <BiGame size={24} />
              </IconContainer>
            }
            subtitle="Название, сайт и социальные сети"
            onClick={() => navigate('/organizer/profile/', { state: { item } })}
          >
            Профиль
          </Cell>
          <Cell
            before={
              <IconContainer>
                <BiLockOpen size={24} />
              </IconContainer>
            }
            subtitle="Добавление и удаление администраторов"
            after={
              stat?.adminsCount ? (
                <Caption className={styles.after}>{stat.adminsCount}</Caption>
              ) : undefined
            }
            onClick={() => navigate('/organizer/admin/', { state: { item } })}
          >
            Доступ к администрированию
          </Cell>
          {!isManualMode && (
            <Cell
              before={
                <IconContainer>
                  <BiDownload size={24} />
                </IconContainer>
              }
              subtitle="Настройка способа сбора данных"
              after={
                <Caption className={styles.after}>{gameSourceTitle}</Caption>
              }
              onClick={() =>
                navigate('/organizer/source/', { state: { item } })
              }
            >
              Источник данных
            </Cell>
          )}
        </Section>
        <Section header="Расписание" footer={scheduleFooter}>
          <Cell
            before={
              <IconContainer>
                <BiCalendarCheck size={24} />
              </IconContainer>
            }
            after={
              stat?.actualGamesCount ? (
                <Caption className={styles.after}>
                  {stat.actualGamesCount}
                </Caption>
              ) : undefined
            }
            onClick={() =>
              navigate('/organizer/games/', {
                state: { item, isCanEdit: isManualMode },
              })
            }
          >
            Список игр
          </Cell>
        </Section>
      </div>
    </div>
  );
};
