import { Text, Title } from '@telegram-apps/telegram-ui';
import { FC } from 'react';

import styles from './index.module.css';

type Props = {
  title: string;
  subTitle?: string;
};

export const PageTitle: FC<Props> = ({ title, subTitle }) => {
  return (
    <div className={styles.wrapper}>
      <Title level="3" weight="1">
        {title}
      </Title>
      {subTitle && <Text className={styles.subTitle}>{subTitle}</Text>}
    </div>
  );
};
